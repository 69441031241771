.nav-link, .nav-link:visited, .nav-link:active, .nav-link:hover, .nav-link:focus {
    color: white;
    text-decoration: none;
    margin-right: 15px;
}

.nav-tabs .nav-link:visited, .nav-tabs .nav-link:active, .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus, .nav-tabs .nav-link {
    color: black;
}

.login-box {
    padding: 25px;
    background-color: #ccc;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.form {
    background-color: #ccc;
    border-radius: 10px;
    padding: 30px;
    max-width: 500px;
    margin: 0 auto;
}

.small-table {
    table-layout: fixed;
    width: 500px;
}

.add-items-table {
    table-layout: fixed;
}

.add-items-table tfoot {
    background-color: #555;
}

.add-items-table tbody {
    background-color: white;
}

.add-items-table thead tr {
    border: none;
}

.add-items-table th {
    border: none;
}

.add-items-table td {
    vertical-align: middle;
}

/* Pagination */
.navigationButtons {
	width: 80%;
	height: 40px;
	list-style: none;
	display: flex;
	justify-content: center;
	margin: auto;
	padding-top: 40px;
	margin-bottom: 80px;
}
	
.navigationButtons a {
	padding: 10px;
	margin: 8px;
	border-radius: 5px;
	border: 1px solid black;
	color: black;
	cursor: pointer;
}
	
.navigationButtons a:hover {
	color: white;
	background-color: #444;
}
	
.navigationActive a {
	color: white;
	background-color: #444;
}
	
.navigationDisabled a {
	color: white;
	background-color: grey;
}

/* Bootprompt fix */
.close {
    font-size: 24px;
    font-weight: bold;
    color: #555;
    outline: none;
    border: none;
    background-color: transparent;
    float: right;
}

.modal-dialog {
    width: 650px !important;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 650px !important;
    }
}